import React from 'react';

import Header from './section/Header/Header';
import Menu from './section/Menu/Menu';
import Articles from './section/Articles/Articles';

const App = () => {
  return (
    <>
    <Header />
    <Menu />
    {/* <Articles /> */}
    </>
  )
}

export default App