import SampleImg from '../../assets/tigadaracatering-link-gallery-4.jpg';
import DekorRumahan from '../../assets/thumbnail-dekorasi-rumahan-08062023.jpg';
import Bundling500 from '../../assets/thumbnail-dekorasi-bundling-500-08062023.jpg';
import Bundling200 from '../../assets/thumbnail-dekorasi-bundling-200-08062023.jpg';
import Gubukan from '../../assets/thumbnail-dekorasi-gubukan-08062023.jpg';


const LinkMenuSmall = [
    {
        title: "Paket Pernikahan",
        image: `${SampleImg}`,
        link: "https://cikarang-karawang-cikampek.tigadaracatering.id/paket-pernikahan",
    },
    {
        title: "Paket Rumahan",
        image: `${DekorRumahan}`,
        link: "https://cikarang-karawang-cikampek.tigadaracatering.id/paket-pernikahan-di-rumah",
    },
    {
        title: "Paket Bundling 200 Tamu",
        image: `${Bundling200}`,
        link: "https://cikarang-karawang-cikampek.tigadaracatering.id/paket-bundling-intimate",
    },
    {
        title: "Paket Bundling 500 Tamu",
        image: `${Bundling500}`,
        link: "https://cikarang-karawang-cikampek.tigadaracatering.id/price-list-bundling-gedung",
    },
    {
        title: "Gubukan",
        image: `${Gubukan}`,
        link: "https://cikarang-karawang-cikampek.tigadaracatering.id/gubukan-murah",
    },
]

export default LinkMenuSmall