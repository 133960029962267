const LinkMenu = [
    {
        title: "Paket Pernikahan",
        link: "https://cikarang-karawang-cikampek.tigadaracatering.id/paket-pernikahan"
    },
    {
        title: "Paket Rumahan",
        link: "https://cikarang-karawang-cikampek.tigadaracatering.id/paket-pernikahan-di-rumah"
    },
    {
        title: "Paket Bundling 200",
        link: "https://cikarang-karawang-cikampek.tigadaracatering.id/paket-bundling-intimate"
    },
    {
        title: "Paket Bundling 500",
        link: "https://cikarang-karawang-cikampek.tigadaracatering.id/price-list-bundling-gedung"
    },
    {
        title: "Gubukan",
        link: "https://cikarang-karawang-cikampek.tigadaracatering.id/gubukan-murah"
    },
]

export default LinkMenu